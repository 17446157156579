import {
  DescribeParametersCommand,
  DescribeParametersCommandInput,
  GetParameterCommand,
  GetParameterCommandInput,
  GetParameterCommandOutput,
  PutParameterCommand,
  PutParameterCommandInput,
  PutParameterCommandOutput,
  SSMClient,
  ParameterMetadata,
} from '@aws-sdk/client-ssm';
import { AssumeRoleWithWebIdentityCommand, STSClient } from '@aws-sdk/client-sts';
import { ENV_AWS_REGION } from 'common/constants/env.constants.ts';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import { IAwsPrerequisitesServiceRes } from 'domains/aws/interfaces/aws.interfaces.ts';

export const awsInitGroupService = async (token: string, role: string): Promise<IAwsPrerequisitesServiceRes> => {
  try {
    const stsClient = new STSClient({
      region: ENV_AWS_REGION,
    });

    const timestamp = ((new Date()).getTime()).toString();
    const input = {
      RoleArn: role,
      RoleSessionName: `card-processing-terminals-${timestamp}`,
      WebIdentityToken: token,
    };
    const command = new AssumeRoleWithWebIdentityCommand(input);
    const assumeRoleWithWebIdentityData = await stsClient.send(command);
    const { Credentials: credentials } = assumeRoleWithWebIdentityData;

    localStorage.setItem(ELocalStorage.AwsTokenExpirationTime, credentials?.Expiration?.getTime().toString() ?? '');

    const ssmClient = new SSMClient({
      region: ENV_AWS_REGION,
      credentials: {
        accessKeyId: credentials?.AccessKeyId ?? '',
        secretAccessKey: credentials?.SecretAccessKey ?? '',
        sessionToken: credentials?.SessionToken ?? '',
      },
    });

    return {
      assumeRoleWithWebIdentityData,
      ssmClient,
    };
  } catch (e) {
    const error = e as Error & { role: string };
    error.role = role;
    throw error;
  }
};

export const awsDescribeParametersRecursiveService = async (
  ssmClient: SSMClient,
  input: DescribeParametersCommandInput,
  acc?: ParameterMetadata[]
): Promise<ParameterMetadata[]> => {
  const command = new DescribeParametersCommand(input);
  const res = await ssmClient.send(command);

  if (res.NextToken) {
    const newData = [
        ...acc ?? [],
        ...res.Parameters ?? [],
    ];

    const hackInput = {
      ...input,
      ...(res.NextToken ? { NextToken: res.NextToken } : null ),
    };

    return awsDescribeParametersRecursiveService(ssmClient, hackInput, newData);
  }

  return [
    ...acc ?? [],
    ...res.Parameters ?? [],
  ];
};

export const awsDescribeParametersService = async (
  ssmClient: SSMClient,
  input: DescribeParametersCommandInput,
): Promise<ParameterMetadata[]> => {
  const res = await awsDescribeParametersRecursiveService(ssmClient, input);

  return res;
};

export const awsGetParameterCommandService = async (
  ssmClient: SSMClient,
  input: GetParameterCommandInput,
): Promise<GetParameterCommandOutput> => {
  const command = new GetParameterCommand(input);
  const res = await ssmClient.send(command);

  return res;
};

export const awsPutParameterCommandService = async (
  ssmClient: SSMClient,
  input: PutParameterCommandInput,
): Promise<PutParameterCommandOutput> => {
  const command = new PutParameterCommand(input);
  const res = await ssmClient.send(command);

  return res;
};
