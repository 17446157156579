import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-input-outlined:focus-within {
    box-shadow: none !important;
  }
  
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    height: 100%;
    min-height: 100vh;
  }
  
  body {
    margin: 0;
    padding: 0;
    background: #FFF;
    font-family: Roboto, sans-serif;
    height: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  #root {
    height: 100%;
    min-height: 100vh;
  }
  
  .ant-layout {
    min-height: 100vh;
  }
  
  .ant-alert {
    border-radius: 0;
  }
`;
