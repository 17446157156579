export const ENV_API_URL = import.meta.env.VITE_API_URL || '';
export const ENV_GOOGLE_O_AUTH_CLIENT_ID = import.meta.env.VITE_GOOGLE_O_AUTH_CLIENT_ID || '';
export const ENV_AWS_TERMINAL_ROOT = import.meta.env.VITE_AWS_TERMINAL_ROOT || '';
export const ENV_AWS_KMS_KEY = import.meta.env.VITE_AWS_KMS_KEY || '';
export const VITE_AWS_ROLE_ARN_TERMINAL_GROUP_READER = import.meta.env.VITE_AWS_ROLE_ARN_TERMINAL_GROUP_READER || '';
export const VITE_AWS_ROLE_ARN_TERMINAL_GROUP_WRITER = import.meta.env.VITE_AWS_ROLE_ARN_TERMINAL_GROUP_WRITER || '';
export const VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER = import.meta.env.VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER || '';
export const VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER = import.meta.env.VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER || '';
export const ENV_AWS_REGION = import.meta.env.VITE_AWS_REGION || '';
export const CURRENCIES_LIST = import.meta.env.VITE_CURRENCIES_LIST || '';
