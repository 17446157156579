import {
  VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER,
  VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER,
  VITE_AWS_ROLE_ARN_TERMINAL_GROUP_READER,
  VITE_AWS_ROLE_ARN_TERMINAL_GROUP_WRITER,
} from 'common/constants/env.constants.ts';

export const AWS_ROLES =  {
  GroupReader: VITE_AWS_ROLE_ARN_TERMINAL_GROUP_READER,
  GroupWriter: VITE_AWS_ROLE_ARN_TERMINAL_GROUP_WRITER,
  EntryReader: VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER,
  EntryWriter: VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER,
} as const;

export const AWS_ROLES_MAPPING = {
  [AWS_ROLES.GroupReader]: {
    value: 'TerminalGroupReader',
    label: 'Read terminal groups',
  },
  [AWS_ROLES.GroupWriter]: {
    value: 'TerminalGroupWriter',
    label: 'Modify terminal groups',
  },
  [AWS_ROLES.EntryReader]: {
    value: 'TerminalEntryReader',
    label: 'Read terminals entries',
  },
  [AWS_ROLES.EntryWriter]: {
    value: 'TerminalEntryWriter',
    label: 'Modify terminal entries',
  },
};
