import { CredentialResponse, googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import routerTree from 'navigation/routerTree.tsx';

export const authLoginService = async (credentialResponse: CredentialResponse): Promise<void> => {
  const { credential } = credentialResponse;

  const userData = jwtDecode(credential ?? '');

  localStorage.setItem(ELocalStorage.Token, credential ?? '');
  localStorage.setItem(ELocalStorage.User, JSON.stringify(userData));

  await routerTree.navigate(ROUTES_MAPPING.PROTECTED.TERMINAL_GROUPS);
};


export const authLogoutService = async (): Promise<void> => {
  googleLogout();

  localStorage.removeItem(ELocalStorage.Token);
  localStorage.removeItem(ELocalStorage.User);

  await routerTree.navigate(ROUTES_MAPPING.PUBLIC.SIGN_IN);
};
