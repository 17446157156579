import { ReactElement, useState } from 'react';
import { Button, Form, Select, notification } from 'antd';
import { AxiosError } from 'axios';
import { useSetAtom } from 'jotai/index';
import { errorAtom } from 'common/atoms/error.atom.tsx';
import { AWS_ROLES, AWS_ROLES_MAPPING } from 'domains/aws/constants/aws-roles.constants.ts';
import { postAccessRolesService } from 'domains/roles/roles.service.ts';

interface IProps {
  error: Error & { role: keyof typeof AWS_ROLES };
}

const AccessDeniedForm = (props: IProps): ReactElement => {
  const { error } = props;

  const rolesFieldOptions = Object.values(AWS_ROLES_MAPPING).map(item => ({
    value: item.value,
    label: <span>{item.label}</span>
  }));

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const setErrorData = useSetAtom(errorAtom);
  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (formValues: { roles: string[] }): Promise<void> => {
    try {
      setIsSubmitLoading(true);

      const { roles } = formValues;
      const reqBody = { roles };
      const { ticket_url: ticketUrl, status } = await postAccessRolesService(reqBody);

      if (status === 'error') {
        setErrorData({
          message: 'Error',
          description: 'The ticket wasn\'t created',
          action: <div />,
        });
      } else {
        api.open({
          message: 'Access Role',
          description: <div>
            Thank you, your request has been registered.
            Here is your ticket: <a href={ticketUrl} target="_blank" rel="noreferrer" >{ticketUrl}</a>
          </div>,
          duration: 0,
        });
      }

      setIsSubmitLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      setIsSubmitLoading(false);
      setErrorData({
        message: error.name,
        description: error.message,
        action: <div />,
      });
    }
  };

  return (
    <Form
      style={{ marginTop: 20 }}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        roles: [AWS_ROLES_MAPPING[error.role].value]
      }}
    >
      {contextHolder}
      <Form.Item
        label="Please request permissions by selecting the desired roles:"
        name="roles"
        rules={[{ required: true, message: 'Field is required' }]}
      >
        <Select
          style={{
            minWidth: 340,
          }}
          mode="multiple"
          placeholder="Select roles"
          options={rolesFieldOptions}
        />
      </Form.Item>
      <Button loading={isSubmitLoading} type="primary" htmlType="submit">
        Create request
      </Button>
    </Form>
  );
};

export default AccessDeniedForm;
