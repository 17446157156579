import { ReactElement } from 'react';
import { useAtom } from 'jotai';
import { Outlet } from 'react-router-dom';
import { errorAtom } from 'common/atoms/error.atom.tsx';
import Alert from 'common/components/alert/alert.component.tsx';

const GeneralLayout = (): ReactElement => {
  const [errorData, setErrorData] = useAtom(errorAtom);

  return (
    <>
      {errorData.message && (
        <Alert
          message={errorData.message}
          description={errorData.description || ''}
          type="error"
          className="custom-error-banner"
          showIcon
          banner
          closable
          onClose={() => {
            setErrorData({
              message: '',
              description: '',
              action: <div />,
            });
          }}
          action={errorData.action}
        />
      )}
      <Outlet />
    </>
  );
};

export default GeneralLayout;
