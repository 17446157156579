import {
  DescribeParametersCommandOutput,
  GetParameterCommandInput,
  SSMClient,
  ParameterMetadata,
} from '@aws-sdk/client-ssm';
import { DescribeParametersCommandInput } from '@aws-sdk/client-ssm/dist-types/commands/DescribeParametersCommand';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER,
  VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER,
  VITE_AWS_ROLE_ARN_TERMINAL_GROUP_READER,
  VITE_AWS_ROLE_ARN_TERMINAL_GROUP_WRITER,
} from 'common/constants/env.constants.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  IAwsPrerequisitesServiceRes,
} from 'domains/aws/interfaces/aws.interfaces.ts';
import {
  awsDescribeParametersService,
  awsGetParameterCommandService,
  awsInitGroupService,
} from 'domains/aws/services/aws.service.ts';

export const awsQueryKeys = createQueryKeys(EQueryKeys.AwsQueryKeys, {
  awsInitGroupReader: (token: string) => ({
    queryKey: ['group-reader', token],
    queryFn: (): Promise<IAwsPrerequisitesServiceRes> =>
      awsInitGroupService(token, VITE_AWS_ROLE_ARN_TERMINAL_GROUP_READER),
  }),
  awsInitGroupWriter: (token: string) => ({
    queryKey: ['group-writer', token],
    queryFn: (): Promise<IAwsPrerequisitesServiceRes> =>
      awsInitGroupService(token, VITE_AWS_ROLE_ARN_TERMINAL_GROUP_WRITER),
  }),
  awsInitEntryReader: (token: string) => ({
    queryKey: ['entry-reader', token],
    queryFn: (): Promise<IAwsPrerequisitesServiceRes> =>
      awsInitGroupService(token, VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER),
  }),
  awsInitEntryWriter: (token: string) => ({
    queryKey: ['entry-writer', token],
    queryFn: (): Promise<IAwsPrerequisitesServiceRes> =>
      awsInitGroupService(token, VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER),
  }),
  awsDescribeParameters: (ssmClient: SSMClient, input: DescribeParametersCommandInput) => ({
    queryKey: ['aws-describe-parameters', input],
    queryFn: (): Promise<ParameterMetadata[]> => awsDescribeParametersService(ssmClient, input),
  }),
  awsGetParameterCommand: (ssmClient: SSMClient, input: GetParameterCommandInput) => ({
    queryKey: ['aws-get-parameters', input],
    queryFn: (): Promise<DescribeParametersCommandOutput> => awsGetParameterCommandService(ssmClient, input),
  }),
});
