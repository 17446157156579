import React, { ReactElement, useEffect, useState } from 'react';
import { GroupOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { SSMClient } from '@aws-sdk/client-ssm';
import { Table, Breadcrumb, Input, Button, Flex, Space } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { ENV_AWS_TERMINAL_ROOT } from 'common/constants/env.constants.ts';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import useErrorHandlerHook from 'common/hooks/useErrorHandler.hook.tsx';
import {
  useAwsDescribeParametersQuery,
  useAwsInitEntryReaderQuery,
} from 'domains/aws/queries/aws.query.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import AddEditGroupTerminalDrawer from './drawer/add-edit-group-terminal.drawer.tsx';
import { EDrawerType } from './enums/group-terminals.enums.ts';
import {
  IGroupTerminalDrawerData,
  IGroupTerminalTableRowRecord,
} from './interfaces/group-terminals.interfaces.ts';

const GroupTerminalsPage = (): ReactElement => {
  const { groupName } = useParams();
  const [
    groupTerminalDrawerData,
    setGroupTerminalDrawerData
  ] = useState<IGroupTerminalDrawerData | Record<string, never>>({});
  const [tableCurrentPage, setTableCurrentPage] = useState<number>(1);
  const [filterMerchantGuidValue, setFilterMerchantGuidValue] = useState<string>('');
  const navigate = useNavigate();
  const token = localStorage.getItem(ELocalStorage.Token) ?? '';
  const { errorHandler } = useErrorHandlerHook();

  const {
    data: awsInitEntryReaderData,
    isLoading: awsInitEntryReaderLoading,
    isError: isAwsInitEntryReaderError ,
    error: awsInitEntryReaderError,
  } = useAwsInitEntryReaderQuery(
    token,
    {
      enabled: !!token,
    }
  );

  const ssmClient = awsInitEntryReaderData?.ssmClient as unknown as SSMClient;

  const {
    data: awsDescribeParametersData,
    isLoading: awsDescribeParametersLoading,
    isError: isAwsDescribeParametersError ,
    error: awsDescribeParametersError,
  } = useAwsDescribeParametersQuery(
    ssmClient,
    {
      ParameterFilters: [
        {
          'Values': [`${ENV_AWS_TERMINAL_ROOT}/entries/${groupName ?? '<!!!unknown-group-name!!!>'}`],
          'Key': 'Path',
          'Option': 'Recursive',
        }
      ],
      // MaxResults: 1,
    },
    {
      enabled: !!Object.keys(awsInitEntryReaderData ?? {}).length,
    }
  );

  const isTableDataLoading = awsInitEntryReaderLoading || awsDescribeParametersLoading;
  const isTableDataHasError = isAwsInitEntryReaderError || isAwsDescribeParametersError;
  const tableDataError = awsInitEntryReaderError ?? awsDescribeParametersError;

  useEffect(() => {
    if (isTableDataHasError && tableDataError) {
      errorHandler(tableDataError);
    }
  }, [errorHandler, isTableDataHasError, tableDataError]);

  const searchByMerchantGuidHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterMerchantGuidValue(event.target.value.toLowerCase());
  };

  const tableData = awsDescribeParametersData?.map((group) => {
    const splitName = group.Name?.split('/').slice(-3);
    const merchantGUID = splitName?.[0];
    const currency = splitName?.[1];

    return {
      merchantGUID,
      currency,
      key: group.ARN,
    };
  }).filter((element) =>
    element.merchantGUID?.includes(filterMerchantGuidValue)
  );

  return (
    <div>
      <Breadcrumb
        style={{
          paddingTop: 24,
          paddingBottom: 24,
        }}
        items={[
          {
            href: '',
            onClick: (e): void => {
              e.preventDefault();
              navigate(ROUTES_MAPPING.PROTECTED.TERMINAL_GROUPS);
            },
            title: (
              <>
                <GroupOutlined />
                <span>Terminal Groups</span>
              </>
            ),
          },
          {
            title: groupName,
          },
        ]}
      />
      <Flex
        justify="space-between"
        gap={24}
        style={{
          paddingBottom: 24,
        }}
      >
        <Input
          placeholder="Search by merchant GUID"
          size="large"
          style={{
            maxWidth: 400,
          }}
          onChange={searchByMerchantGuidHandler}
          suffix={<SearchOutlined />}
          allowClear
        />
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => {
            setGroupTerminalDrawerData({
              type: EDrawerType.AddNew,
              groupName: groupName ?? '',
            });
          }}
        >
          Add new terminal
        </Button>
      </Flex>
      <Table
        dataSource={tableData}
        loading={isTableDataLoading}
        pagination={{
          current: tableCurrentPage,
          pageSize: 30,
        }}
        onChange={(pagination) => {
          setTableCurrentPage(pagination.current ?? 1);
        }}
      >
        <Table.Column
          title="Merchant GUID"
          dataIndex="merchantGUID"
          key="merchantGUID"
          width="50%"
        />
        <Table.Column
          title="Currency"
          dataIndex="currency"
          key="currency"
          width="50%"
        />
        <Table.Column
          key="action"
          render={(_, record: IGroupTerminalTableRowRecord) => (
            <Space size="middle">
              <Button
                onClick={() => {
                  setGroupTerminalDrawerData({
                    record,
                    type: EDrawerType.Edit,
                    groupName: groupName ?? '',
                  });
                }}
              >
                Edit
              </Button>
            </Space>
          )}
        />
      </Table>
      <AddEditGroupTerminalDrawer
        width="50%"
        open={!!Object.keys(groupTerminalDrawerData).length}
        data={groupTerminalDrawerData}
        onClose={() => {
          setGroupTerminalDrawerData({});
        }}
      />
    </div>
  );
};

export default GroupTerminalsPage;
