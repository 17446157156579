import React, { ReactElement, useEffect, useState } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { SSMClient } from '@aws-sdk/client-ssm';
import { Table, Button, Flex, Input, Space } from 'antd';
import { Link } from 'react-router-dom';
import { ENV_AWS_TERMINAL_ROOT } from 'common/constants/env.constants.ts';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import useErrorHandlerHook from 'common/hooks/useErrorHandler.hook.tsx';
import {
  useAwsDescribeParametersQuery,
  useAwsInitGroupReaderQuery,
} from 'domains/aws/queries/aws.query.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import AddEditTerminalGroupDrawer from './drawer/add-edit-terminal-group.drawer.tsx';
import { EDrawerType } from './enums/terminal-groups.enums.ts';
import { ITerminalGroupDrawerData, ITerminalGroupTableRowRecord } from './interfaces/terminal-groups.interfaces.ts';

const TerminalGroupsPage = (): ReactElement => {
  const [terminalGroupDrawerData, setTerminalGroupDrawerData]
    = useState<ITerminalGroupDrawerData | Record<string, never>>({});
  const [filterGroupNameValue, setFilterGroupNameValue] = useState<string>('');
  const token = localStorage.getItem(ELocalStorage.Token) ?? '';
  const { errorHandler } = useErrorHandlerHook();

  const {
    data: awsInitGroupReaderData,
    isLoading: awsInitGroupReaderLoading,
    isError: isAwsInitGroupReaderError ,
    error: awsInitGroupReaderError,
  } = useAwsInitGroupReaderQuery(
    token,
    {
      enabled: !!token,
    }
  );

  const ssmClient = awsInitGroupReaderData?.ssmClient as unknown as SSMClient;

  const {
    data: awsDescribeParametersData,
    isLoading: awsDescribeParametersLoading,
    isError: isAwsDescribeParametersError ,
    error: awsDescribeParametersError,
  } = useAwsDescribeParametersQuery(
    ssmClient,
    {
      ParameterFilters: [
        {
          'Values': [`${ENV_AWS_TERMINAL_ROOT}/schemas`],
          'Key': 'Path',
          'Option': 'Recursive',
        }
      ],
      // MaxResults: 1,
    },
    {
      enabled: !!Object.keys(awsInitGroupReaderData ?? {}).length,
    }
  );

  const isTableDataLoading = awsInitGroupReaderLoading || awsDescribeParametersLoading;
  const isTableDataHasError = isAwsInitGroupReaderError || isAwsDescribeParametersError;
  const tableDataError = awsInitGroupReaderError ?? awsDescribeParametersError;

  useEffect(() => {
    if (isTableDataHasError && tableDataError) {
      errorHandler(tableDataError);
    }
  }, [errorHandler, isTableDataHasError, tableDataError]);

  const searchByGroupNameHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterGroupNameValue(event.target.value.toLowerCase());
  };

  const tableData = awsDescribeParametersData?.map((group) => {
    const groupName = group.Name?.split('/').slice(-2)[0];

    return {
      groupName,
      key: group.ARN,
    };
  }).filter((element) =>
    element.groupName?.toLowerCase().includes(filterGroupNameValue)
  );

  return (
    <>
      <Flex
        justify="space-between"
        gap={24}
        style={{
          paddingBottom: 24,
        }}
      >
        <Input
          placeholder="Search by group name"
          size="large"
          style={{
            maxWidth: 400,
          }}
          onChange={searchByGroupNameHandler}
          suffix={<SearchOutlined />}
          allowClear
        />
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => {
            setTerminalGroupDrawerData({
              type: EDrawerType.AddNew,
            });
          }}
        >
          Add new terminal group
        </Button>
      </Flex>
      <Table
        dataSource={tableData}
        loading={isTableDataLoading}
        pagination={false}
      >
        <Table.Column
          title="Group name"
          dataIndex="groupName"
          key="groupName"
          width="100%"
          render={(groupName) =>
            <Link to={`${ROUTES_MAPPING.PROTECTED.TERMINAL_GROUPS}/${groupName as string}`}>
              {groupName}
            </Link>}
        />
        <Table.Column
          key="action"
          render={(_, record: ITerminalGroupTableRowRecord) => (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTerminalGroupDrawerData({
                    type: EDrawerType.Edit,
                    record,
                  });
                }}
              >
                Edit
              </Button>
            </Space>
          )}
        />
      </Table>
      <AddEditTerminalGroupDrawer
        width="70%"
        open={!!Object.keys(terminalGroupDrawerData).length}
        data={terminalGroupDrawerData}
        onClose={() => {
          setTerminalGroupDrawerData({});
        }}
      />
    </>
  );
};

export default TerminalGroupsPage;
