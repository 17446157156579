import React, { ReactElement } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactQueryRewind from 'react-query-rewind';
import { RouterProvider } from 'react-router';
import { queryClient } from 'common/clients/query.client.ts';
import { ENV_GOOGLE_O_AUTH_CLIENT_ID } from 'common/constants/env.constants.ts';
import routerTree from 'navigation/routerTree.tsx';
import { GlobalStyle } from 'theme/global.styles.ts';

const App = (): ReactElement => (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={ENV_GOOGLE_O_AUTH_CLIENT_ID}>
        <GlobalStyle />
        <RouterProvider router={routerTree} />
        <ReactQueryRewind />
        <ReactQueryDevtools position="right" />
      </GoogleOAuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

export default App;
