import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';

export const apiClient = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL,
});

const apiClientInterceptorsReq = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => ({
  ...config,
});

const apiClientInterceptorsReqError = async (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const apiClientInterceptorsRes = (response: AxiosResponse): AxiosResponse => response;

const apiClientInterceptorsResError = async (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

apiClient.interceptors.request.use(apiClientInterceptorsReq, apiClientInterceptorsReqError);
apiClient.interceptors.response.use(apiClientInterceptorsRes, apiClientInterceptorsResError);
