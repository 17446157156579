export const ROUTES_MAPPING = {
  ROOT: '/',
  NO_MATCH: '/404',
  ERROR:'/error',
  PUBLIC: {
    SIGN_IN: '/sign-in',
  },
  PROTECTED: {
    TERMINAL_GROUPS: '/terminal-groups',
  },
};
