import { useState, ReactNode, useEffect } from 'react';
import { SSMClient, ParameterTier, ParameterType } from '@aws-sdk/client-ssm';
import { Drawer, Form, Input, Button, DrawerProps, Flex, InputNumber, Select } from 'antd';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { CURRENCIES_LIST, ENV_AWS_KMS_KEY, ENV_AWS_TERMINAL_ROOT } from 'common/constants/env.constants.ts';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import useErrorHandlerHook from 'common/hooks/useErrorHandler.hook.tsx';
import {
  useAwsGetParameterCommandQuery,
  useAwsInitEntryWriterQuery,
  useAwsInitGroupReaderQuery,
  useAwsPutParameterCommandMutation,
} from 'domains/aws/queries/aws.query.ts';
import { ISchema } from 'domains/group-terminal-schema/interfaces/group-terminal-schema.interface.ts';
import { EDrawerType } from 'pages/group-terminals/enums/group-terminals.enums.ts';
import { IGroupTerminalDrawerData } from 'pages/group-terminals/interfaces/group-terminals.interfaces.ts';

interface IProps extends DrawerProps {
  data: IGroupTerminalDrawerData | Record<string, never>;
}

enum EFormItems {
  MerchantGUID = 'merchantGUID',
  Currency = 'currency',
  Label = 'Label',
}

const AddEditGroupTerminalDrawer = (props: IProps): ReactNode => {
  const {
    onClose,
    open,
    data: {
      groupName,
      type: drawerType,
      record,
    },
  } = props;
  const [form] = Form.useForm();
  const [isFromSubmitting, setIsFromSubmitting] = useState(false);
  const token = localStorage.getItem(ELocalStorage.Token) ?? '';
  const { errorHandler } = useErrorHandlerHook();

  const {
    data: awsInitGroupReaderData,
    isLoading: isAwsInitGroupReaderDataLoading,
    isError: isAwsInitGroupReaderDataError ,
    error: awsInitGroupReaderDataError,
  } = useAwsInitGroupReaderQuery(
    token,
    {
      enabled: !!token && open,
    }
  );

  const ssmGroupReaderClient = awsInitGroupReaderData?.ssmClient as unknown as SSMClient;
  const {
    data: awsGetSchemaJsonData,
    isLoading: isAwsGetSchemaJsonDataLoading,
    isError: isAwsGetSchemaJsonDataError ,
    error: awsGetSchemaJsonDataError,
  } = useAwsGetParameterCommandQuery(
    ssmGroupReaderClient,
    {
      Name: `${ENV_AWS_TERMINAL_ROOT}/schemas/${groupName ?? '<!!!unknown-group-name!!!>'}/_schema.json`,
      WithDecryption: true,
    },
    {
      enabled: !!Object.keys(awsInitGroupReaderData ?? {}).length && !!groupName && open,
    }
  );

  const {
    data: awsInitEntryWriterData,
    isLoading: isAwsInitEntryWriterDataLoading,
    isError: isAwsInitEntryWriterDataError ,
    error: awsInitEntryWriterDataError,
  } = useAwsInitEntryWriterQuery(
    token,
    {
      enabled: !!token && !!groupName && open,
    }
  );

  const ssmClient = awsInitEntryWriterData?.ssmClient as unknown as SSMClient;
  const {
    data: awsGetJsonData,
    isLoading: isAwsGetJsonDataLoading,
    isError: isAwsGetJsonDataError ,
    error: awsGetJsonDataError,
  } = useAwsGetParameterCommandQuery(
    ssmClient,
    {
      Name: `${ENV_AWS_TERMINAL_ROOT}/entries`
        + `/${groupName ?? '<!!!unknown-group-name!!!>'}`
        + `/${record?.merchantGUID ?? '<!!!unknown-merchant-guid!!!>'}`
        + `/${record?.currency ?? '<!!!unknown-currency!!!>'}/terminal.json`,
      WithDecryption: true,
    },
    {
      enabled: drawerType === EDrawerType.Edit && !!Object.keys(awsInitEntryWriterData ?? {}).length && open,
    }
  );

  const isDrawerDataLoading = isAwsInitEntryWriterDataLoading || isAwsGetJsonDataLoading
    || isAwsInitGroupReaderDataLoading || isAwsGetSchemaJsonDataLoading;
  const isDrawerDataHasError = isAwsInitEntryWriterDataError || isAwsGetJsonDataError
    || isAwsInitGroupReaderDataError || isAwsGetSchemaJsonDataError;
  const drawerDataError = awsInitEntryWriterDataError ?? awsGetJsonDataError
    ?? awsInitGroupReaderDataError ?? awsGetSchemaJsonDataError;

  useEffect(() => {
    if (isDrawerDataHasError && drawerDataError) {
      errorHandler(drawerDataError);
      // @ts-expect-error Can't pass event object
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerDataHasError]);

  const { mutateAsync: mutateAsyncAwsPutParameterCommandMutation } = useAwsPutParameterCommandMutation();

  useEffect(() => {
    if (drawerType === EDrawerType.Edit && awsGetJsonData?.Parameter?.Value) {
      const terminalFieldsData = JSON.parse(awsGetJsonData.Parameter.Value) as Record<string, string | number>;

      const fieldSet = Object.entries(terminalFieldsData).map(([key, value]) => ({
        name: key,
        value,
      }));

      form.setFields([
        {
          name: EFormItems.MerchantGUID,
          value: record?.merchantGUID,
        },
        {
          name: EFormItems.Currency,
          value: record?.currency,
        },
        ...fieldSet,
      ]);
    }
  }, [record, drawerType, form, awsGetJsonData?.Parameter?.Value, awsGetSchemaJsonData?.Parameter?.Value]);

  const onSubmitForm = async (
    {
      merchantGUID,
      currency,
      ...fieldsSet
    }: Record<EFormItems, string>
  ): Promise<void> => {
    try {
      setIsFromSubmitting(true);

      const ssmClient = awsInitEntryWriterData?.ssmClient as unknown as SSMClient;

      await mutateAsyncAwsPutParameterCommandMutation({
        ssmClient,
        input: {
          Name:
            `${ENV_AWS_TERMINAL_ROOT}/entries/${groupName ?? '<!!!unknown-group-name!!!>'}`
            + `/${merchantGUID}/${currency}/terminal.json`,
          Value: JSON.stringify(fieldsSet),
          Type: ParameterType.SECURE_STRING,
          Tier: ParameterTier.ADVANCED,
          Overwrite: drawerType === EDrawerType.Edit,
          KeyId: ENV_AWS_KMS_KEY,
          ...(fieldsSet[EFormItems.Label] && drawerType !== EDrawerType.Edit ? {
            Tags: [{
              Key: EFormItems.Label,
              Value: fieldsSet[EFormItems.Label],
            }],
          } : {})
        }
      });

      await queryClient.invalidateQueries({
        queryKey: queryKeysClient[EQueryKeys.AwsQueryKeys].awsDescribeParameters._def,
      });

      setIsFromSubmitting(false);
      // @ts-expect-error Can't pass event object
      onClose();
    } catch (error) {
      errorHandler(error as Error);
      setIsFromSubmitting(false);
      // @ts-expect-error Can't pass event object
      onClose();
    }
  };

  return (
    <Drawer
      {...props}
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
        }
      }}
      loading={isDrawerDataLoading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmitForm}
      >
        <Form.Item
          label="Merchant GUID"
          name={EFormItems.MerchantGUID}
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input disabled={drawerType === EDrawerType.Edit} />
        </Form.Item>
        <Form.Item
          label="Currency Value"
          name={EFormItems.Currency}
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Select
            options={[
              ...(CURRENCIES_LIST.split(',').map(item => ({
                value: item,
                label: item,
              })))
            ]}
          />
        </Form.Item>
        {
          awsGetSchemaJsonData?.Parameter?.Value
          && Object.entries((JSON.parse(awsGetSchemaJsonData.Parameter.Value) as ISchema).properties)
            .map(([key, data]) => (
              <Form.Item
                label={key}
                key={key}
                name={key}
                rules={[{ required: data.required, message: 'Field is required' }]}
              >
                {
                  {
                    'string': <Input />,
                    'integer': <InputNumber style={{ width: '100%' }} />,
                  }[data.type]
                }
              </Form.Item>
            ))
        }
        <Flex
          justify="flex-end"
          gap={24}
          style={{
            paddingBottom: 24,
          }}
        >
          <Button
            key="close"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={isFromSubmitting}
            onClick={form.submit}
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default AddEditGroupTerminalDrawer;
