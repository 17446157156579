import { apiClient } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';

export interface IRolesAccessReq {
  roles: string[],
}

export interface IRolesAccessRes {
  status: string,
  error: string,
  ticket_url: string,
}

export const postAccessRolesService = async (
  data: IRolesAccessReq
): Promise<IRolesAccessRes> => {
  const url = API_ENDPOINTS.ROLES_ACCESS_REQUEST;
  const config = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem(ELocalStorage.Token) ?? ''}`,
    },
  };
  const res = await apiClient.post<IRolesAccessRes>(url, data, config);

  return res.data;
};
