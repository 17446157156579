import { Alert } from 'antd';
import styled from 'styled-components';

export const SAlert = styled(Alert)`
  flex-direction: column;
  padding-left: 72px;
  padding-right: 60px;
  
  .ant-alert-icon {
    position: absolute;
    left: 24px;
    top: 20px;
  }
  
  .ant-alert-close-icon {
    position: absolute;
    right: 24px;
    top: 20px;
  }
`;
