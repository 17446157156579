import styled from 'styled-components';

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;
  background-color: #f0f2f5;
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
`;

export const SLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 500px;
  height: auto;
  min-height: 265px;
  padding: 40px 56px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, .08);
  grid-row-gap: 12px;
  row-gap: 12px;
`;

export const STitle = styled.div`
  font-size: 21px;
  text-align: center;
`;
