export enum ETerminalFieldsTypeTypes {
  String = 'string',
  Integer = 'integer',
}

export enum ETerminalFields {
  Name = 'name',
  Description = 'description',
  Type = 'type',
  Required = 'required',
}
