import { ReactElement } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, SpinProps } from 'antd';
import { SContainer } from './loader.component.styles.ts';

export const Loader = (props: SpinProps): ReactElement => (
  <SContainer>
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      {...props}
    />
  </SContainer>
);
