import { ReactElement, useEffect, useState } from 'react';
import { Outlet, Navigate, useNavigation } from 'react-router-dom';
import { Loader } from 'common/components/loader/loader.component.tsx';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import { IAuthUserData } from 'domains/auth/interfaces/auth.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';

const PublicRouteGuard = (): ReactElement => {
  const { state } = useNavigation();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem(ELocalStorage.User) ?? '{}') as IAuthUserData;
    setIsLoggedIn(!!userData.sub);
  }, []);

  return isLoggedIn === null ? <Loader fullscreen /> : (
    <>
      {isLoggedIn ? <Navigate to={ROUTES_MAPPING.PROTECTED.TERMINAL_GROUPS} /> : <Outlet />}
      {state === 'loading' && <Loader fullscreen />}
    </>
  );
};

export default PublicRouteGuard;
